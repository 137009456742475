import firebase from 'firebase/app'
import 'firebase/auth'

// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: 'AIzaSyDJ1ASQGT3vqAzUOHBtKrtuzYh7i91dqmU',
  authDomain: 'vrtnieuwshub.firebaseapp.com',
  databaseURL: 'https://vrtnieuwshub.firebaseio.com',
  projectId: 'project-1407104807720831955',
  storageBucket: 'project-1407104807720831955.appspot.com',
  messagingSenderId: '366306179341',
  appId: '1:366306179341:web:8d45b38751c4ab73'
}

firebase.initializeApp(config)
