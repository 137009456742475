export default {
  setOrderTotalPrice: (state, orderTotalPrice) =>
    (state.orderTotalPrice = orderTotalPrice),

  setOrderTotalNumber: (state, orderTotalNumber) =>
    (state.orderTotalNumber = orderTotalNumber),

  setOrderPerProduct: (state, orderPerProduct) =>
    (state.orderPerProduct = orderPerProduct),

  setTableNumber: (state, value) => (state.tableNumber = value),

  setConfirmDialogOpen: (state, status) => (state.confirmDialogOpen = status),

  setEditOrderPending: (state, value) => (state.editOrderPending = value),

  addOneToOrder: (state, productId) => {
    if (state.orderPerProduct[productId] == undefined) {
      state.orderPerProduct = { ...state.orderPerProduct, [productId]: 1 }
    } else {
      let value = state.orderPerProduct[productId]
      value++
      state.orderPerProduct = {
        ...state.orderPerProduct,
        [productId]: value
      }
    }
  },

  removeOneFromOrder: (state, productId) => {
    let value = state.orderPerProduct[productId]
    value--
    state.orderPerProduct = {
      ...state.orderPerProduct,
      [productId]: value
    }
  },

  updateTotal: (state, price) => {
    state.orderTotalPrice = state.orderTotalPrice + price
  },

  updateTotalNumber: (state, value) => {
    state.orderTotalNumber = state.orderTotalNumber + value
  }
}
