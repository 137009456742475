import state from './order.state'
import mutations from './order.mutations'
import actions from './order.actions'
import getters from './order.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
