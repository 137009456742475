export default {
  /* Product input name */
  setProductNameToCreate: (state, productNameToCreate) =>
    (state.productNameToCreate = productNameToCreate),

  /* Product input price */
  setProductPriceToCreate: (state, productPriceToCreate) =>
    (state.productPricetoCreate = productPriceToCreate),

  /* Products */
  setProducts: (state, products) => {
    products = products.sort(
      (a, b) => parseFloat(a.order) - parseFloat(b.order)
    )
    state.products = products
  },
  addProduct: (state, product) => state.products.push(product),
  removeProductById: (state, productId) => {
    const index = state.products.findIndex(product => product.id === productId)
    state.products.splice(index, 1)
  },

  /* Products deletion */
  addProductDeletionPending: (state, productId) =>
    state.productDeletionPending.push(productId),
  removeProductDeletionPending: (state, productId) => {
    const index = state.products.findIndex(product => product.id === productId)
    state.productDeletionPending.splice(index, 1)
  },

  /* Product creation */
  setProductCreationPending: (state, value) =>
    (state.productCreationPending = value)
}
