import OrdersDB from '@/firebase/orders-db'
import axios from 'axios'

export default {
  addOneToOrder: async ({ commit, rootGetters }, product) => {
    const price = rootGetters['products/getProductById'](product).price

    commit('addOneToOrder', product)
    commit('updateTotal', price)
    commit('updateTotalNumber', 1)
    commit('setEditOrderPending', false)
  },

  removeOneFromOrder: async ({ commit, state, rootGetters }, product) => {
    if (
      state.orderPerProduct[product] === undefined ||
      state.orderPerProduct[product] === 0
    )
      return
    const price = rootGetters['products/getProductById'](product).price
    commit('setEditOrderPending', true)
    commit('removeOneFromOrder', product)
    commit('updateTotal', -price)
    commit('updateTotalNumber', -1)
    commit('setEditOrderPending', false)
  },

  printOrder: async ({ state, commit, rootState, rootGetters }, order) => {
    const products = order.order
    const productsForOrder = []
    const totalPrice = state.orderTotalPrice
    const totalNumber = state.orderTotalNumber
    const tableNumber = state.tableNumber

    Object.keys(products).forEach(function(id) {
      const updatedProduct = {
        count: products[id],
        name: rootGetters['products/getProductById'](id).name,
        unitPrice: rootGetters['products/getProductById'](id).price,
        totalPrice:
          rootGetters['products/getProductById'](id).price * products[id]
      }
      productsForOrder.push(updatedProduct)
    })
    axios
      .post(
        'http://6e15a34c.ngrok.io/',
        {
          products: productsForOrder,
          totalPrice: totalPrice,
          totalNumber: totalNumber,
          tableNumber: tableNumber
        },
        {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded'
          }
        }
      )
      .then(function(response) {
        console.log(response.data) // ex.: { user: 'Your User'}
        console.log(response.status) // ex.: 200
        return
      })
      .catch(function(error) {
        console.log(error)
      })
  },

  showConfirmDialog: ({ dispatch, state, commit }) => {
    commit('setConfirmDialogOpen', true)
  },

  finishOrder: async ({ state, commit, rootState, rootGetters }) => {
    const ordersDb = new OrdersDB(rootState.authentication.user.id)
    const user = rootState.authentication.user.id
    if (state.orderPerProduct === {}) return
    const products = state.orderPerProduct
    const productsForOrder = []
    Object.keys(products).forEach(function(id) {
      const updatedProduct = {
        count: products[id],
        name: rootGetters['products/getProductById'](id).name,
        unitPrice: rootGetters['products/getProductById'](id).price,
        totalPrice:
          rootGetters['products/getProductById'](id).price * products[id]
      }
      productsForOrder.push(updatedProduct)
    })

    const order = {
      user: user,
      printStatus: 0,
      order: productsForOrder,
      totalPrice: state.orderTotalPrice,
      tableNumber: state.tableNumber,
      totalNumber: state.orderTotalNumber
    }

    // This is when the order is send through a POST call,
    // But we are now using a watcher on the firestore inside the node server for the printer
    // dispatch('printOrder', order)

    commit('setOrderPerProduct', {})
    commit('setOrderTotalPrice', 0)
    commit('setOrderTotalNumber', 0)
    commit('setTableNumber', 0)
    commit('setEditOrderPending', true)

    const createdOrder = await ordersDb.create(order)

    // commit('addProduct', createdOrder)
    commit('setEditOrderPending', false)
  }
}
